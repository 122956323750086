<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">지식분류 관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
          <div class="form-inp  ml-2">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                hide-details
                :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>

        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="aspNweCustKeyisNull('select')">조회</v-btn>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <!-- 메뉴 -->
      <div class="box-wrap col-3">
        <h2 class="tit-h2 d-flex">지식컨텐츠 분류
          <div class="ml-auto align-self-center mr-0">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddLV1')" outlined class="btn-default" @click="aspNweCustKeyisNull('firstNode')">1레벨 메뉴추가</v-btn>
            <!-- 1레벨 메뉴 추가 모달 -->
            <v-dialog max-width="655" persistent v-model="dialogM510101P01" v-if="dialogM510101P01"
              content-class="square-modal min-auto"
            >
              <dialog-M510101P01
                  headerTitle="분류 1레벨 정보"
                  p01
                  :aspCustKey="this.ASP_NEWCUST_KEY"
                  @insert="insertTreeView"
                  @hide="hideDialog('M510101P01')"
                  @select="treeviewListBtn"
              >
              </dialog-M510101P01>
            </v-dialog>

          </div>
        </h2>
        <div class="" style="height: 599px">
          <div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnUnfold')" outlined class="btn-default" @click="unfoldTreeview">전체펼치기</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFold')" outlined class="btn-default ml-2" @click="foldTreeview">전체접기</v-btn>

          </div>
          <div class="tree-wrap mt-2 scrollable" style="height: 559px">
            <template>
              <v-treeview
                :items="treeItems"
                item-disabled="locked"
                activatable
                open-on-click
                ref="treeview1"
              >
                <template v-slot:prepend="{ open, item }" >
                  <v-icon color="#8B98DD" v-if="item.children">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                </template>

                <template v-slot:label="{ item }">
                  <div @click="selectTreeview(item)">{{item.name}}
                    <v-icon color="#F06969" >
                      {{  item.scrDispYn == 'N' ? 'mdi-close-circle-outline' : '' }}
                    </v-icon>
                  </div>
                </template>
              </v-treeview>
            </template>
          </div>
        </div>
      </div>
      <!-- 지식컨텐츠 분류상세 -->
      <div class="box-wrap flex-grow-1 ml-3">
        <h2 class="tit-h2 d-flex">지식컨텐츠 분류상세
          <div class="ml-auto align-self-center mr-0">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd')" outlined class="btn-point" @click="aspNweCustKeyisNull('add')">추가</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" @click="aspNweCustKeyisNull('delete')">삭제</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="aspNweCustKeyisNull('save') ">저장</v-btn>
          </div>
        </h2>
        <div class="box-ct">
          <div class="table-form">
              <table>
                <caption class="hide">
                  항목
                </caption>
                <colgroup>
                  <col width="120px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">등록자소속</th>
                    <td >
                      {{REGR_USER_ATTR_NM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">등록자</th>
                    <td >
                      {{REGR_NM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      등록일시
                    </th>
                    <td >
                      {{REG_DTTM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      수정자소속
                    </th>
                    <td >
                      {{AMDR_USER_ATTR_NM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      수정자
                    </th>
                    <td >
                      {{AMDR_NM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      수정일시
                    </th>
                    <td >
                      {{UPD_DTTM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      상위분류 관리번호
                    </th>
                    <td >
                      {{PRE_NODE_KEY}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      분류관리번호
                    </th>
                    <td>
                      {{DIV_ID}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">분류명</v-badge>
                    </th>
                    <td>
                       <v-text-field
                        class="form-inp full"
                        name="name"
                        outlined
                        hide-details
                       v-model="DIV_NM"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      분류속성
                    </th>
                    <td >
                      <v-checkbox
                        :on-icon="'svg-chk-on'"
                        :off-icon="'svg-chk-off'"
                        label="화면표시안함"
                        hide-details
                        v-model="SCR_DISP_YN"
                        ></v-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      템플릿타입
                    </th>
                    <td >
                      {{TMPLAT_TYPE_NM}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      템플릿명
                    </th>
                    <td >
                      <div class="d-flex">
                        <v-text-field
                          class="form-inp"
                          name="name"
                          outlined
                          hide-details
                          readonly
                          v-model="TMPLAT_TITL"
                        >
                        </v-text-field>
                        <div class="ml-2 align-self-center">
                          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFind')" outlined class="btn-default" @click="aspNweCustKeyisNull('find')">찾기</v-btn>
                          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPreview')" outlined class="btn-default ml-2" @click="aspNweCustKeyisNull('preview')">미리보기</v-btn>
                          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitTmp')" outlined class="btn-point ml-2" @click="resetTmplat">초기화</v-btn>
                        </div>
                      </div>
                      <!-- 찾기 모달 -->
                      <v-dialog max-width="655" persistent v-model="dialogM510101P02" v-if="dialogM510101P02"
                        content-class="square-modal modal-white"
                      >
                        <dialog-M510101P01
                          headerTitle="지식 컨텐츠 템플릿 찾기"
                          p02
                          :aspCustKey="this.ASP_NEWCUST_KEY"
                          @openPreview="previewTmplat"
                          @selectId="selectedTmplat"
                          @hide="hideDialog('M510101P02')"
                        >
                        </dialog-M510101P01>
                      </v-dialog>

                      <!-- 미리보기 모달 -->
                      <v-dialog max-width="655" persistent v-model="dialogM510101P03" v-if="dialogM510101P03"
                        content-class="square-modal modal-white">
                        <dialog-M510101P01
                            headerTitle="지식 컨텐츠 템플릿 미리보기"
                            p03
                            :previewContn="previewParams"
                            @hide="hideDialog('M510101P03')"
                        >
                        </dialog-M510101P01>
                      </v-dialog>

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      선택경로
                    </th>
                    <td >
                      <div class="tree-wrap mt-2">
                        <template>
                          <v-treeview
                              :items="selectedTreeItems"
                              item-disabled="locked"
                              activatable
                              :open-all = true
                              ref="treeview2"
                          >
                            <template v-slot:prepend="{ open, item }" >
                              <v-icon color="#8B98DD" v-if="item.children">
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                              </v-icon>
                            </template>

                            <template v-slot:label="{ item }">
                              {{ item.name }}
                              <v-icon color="#F06969" >
                                {{ item.scrDispYn == 'N' ? 'mdi-close-circle-outline' : '' }}
                              </v-icon>
                            </template>
                          </v-treeview>
                        </template>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DialogM510101P01 from "./M510101P01.vue";
import {mixin} from '../../mixin/mixin.js'

export default {
  mixins: [mixin],
  name: "MENU_M510101", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM510101P01,
  },
  updated(){
    //분류트리상세 경로 오픈
    this.$refs.treeview2.updateAll(true);
  },
  data() {
    return {
      dialogM510101P01: false,
      dialogM510101P02: false,
      dialogM510101P03: false,

      treeItems: [],
      selectedTreeItems: [],

      ASP_NEWCUST_KEY: '',

      REGR_USER_ATTR_NM: '',
      REGR_NM: '',
      REG_DTTM: '',
      AMDR_USER_ATTR_NM: '',
      AMDR_NM: '',
      UPD_DTTM: '',
      PRE_NODE_KEY: '',
      DIV_ID: '',
      DIV_NM: '',
      SCR_DISP_YN: false,
      TMPLAT_TYPE_NM: '',
      TMPLAT_TITL: '',
      TMPLAT_ID: '',

      NEW_DIV_ID: [],
      SELECTED_DIV_ID: '',
      SELECTED_NODE_LV: '',

      previewParams: '',
      
      RDOnly:false,
    };
  },
  methods: {
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    unfoldTreeview(){
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview(){
      this.$refs.treeview1.updateAll(false);
    },
    aspNweCustKeyisNull(btnType) {
      //필수조회 값 확인
      if (this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null) {
        this.common_alert("회사 구분을 선택해주세요.", 'error');
        return;
      }

      if(btnType == 'select'){
        //분류상세초기화
        this.resetDetail('', 'select');

        //지식컨텐츠 분류 조회
        this.treeviewListBtn();

      }else if(btnType == 'firstNode'){
        this.showDialog('M510101P01');

      }else if(btnType == 'add'){
        this.newTreeView();

      }else if (btnType == 'delete'){
        if(this.SELECTED_DIV_ID == '' || this.SELECTED_DIV_ID == null){
          this.common_alert("노드가 선택되지 않았습니다.", 'error');
          return;
        };

        this.common_confirm('삭제 하시겠습니까?', this.deleteTreeBtn, null, null, null, 'confirm');

      }else if (btnType == 'save'){
        if(this.SELECTED_DIV_ID == '' || this.SELECTED_DIV_ID == null){
          this.common_alert("노드가 선택되지 않았습니다.", 'error');
          return;
        };

        if(this.DIV_NM == '' || this.DIV_NM == null){
          this.common_alert("분류명을 입력해주세요.", 'error');
          return;
        };

        this.common_confirm('저장 하시겠습니까?', this.saveTreeView, null, null, null, 'confirm');

      }else if (btnType == 'find'){
        if(this.SELECTED_DIV_ID == '' || this.SELECTED_DIV_ID == null){
          this.common_alert("노드가 선택되지 않았습니다.", 'error');
          return;
        };

        this.showDialog('M510101P02');

      }else if (btnType == 'preview'){
        if(this.SELECTED_DIV_ID == '' || this.SELECTED_DIV_ID == null){
          this.common_alert("노드가 선택되지 않았습니다.", 'error');
          return;
        };

        this.mainPreview();

      };

    },
    async treeviewListBtn(){
      //콜백처리결과
      let requestData = {
        headers: {},
        sendData: {},
      };

      //헤더 세팅
      requestData.headers["URL"] = "/api/km/category/manage/tr/inqire";
      requestData.headers["SERVICE"] = "km.category.manage.tr";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;

      let response = await this.common_postCall(requestData);
      this.treeviewListRes(response);

    },
    treeviewListRes(response){
      let data = response.DATA;
      let items = [];

      if(data.length !=0 ){
        for(var i=0; i<data.length; i++){
          let element = {
            cNode: data[i].C_NODE_NO,       //분류ID
            pNode: data[i].P_NODE_NO,       //상위분류ID
            id: data[i].NODE_ID,            //item-key
            name: data[i].NODE_TITLE,       //item-text
            nodeLv: data[i].NODE_LVL ,      //분류 레벨 (start 0)
            nodeSort: data[i].NODE_SORT,    //정렬순서
            nodeType: data[i].NODE_TYPE,    //default 'D'
            scrDispYn: data[i].SCR_DISP_YN, //화면표시
          }

          element.children = [];

          //화면표시 유무
          if(data[i].SCR_DISP_YN == "N"){
            element["locked"] = true;
          }

          if(element.nodeLv == 2){
            for(var j=0; j<items.length; j++){
              if(element.pNode == items[j].cNode) {
                items[j].children.push(element);
              }
            }
          } else if (element.nodeLv == 3){
            //마지막 lv
            for(var k=0; k<items.length; k++){
              for(var l=0; l<items[k].children.length; l++){
                if(element.pNode == items[k].children[l].cNode){
                  items[k].children[l].children.push(element);
                }
              }
            }
          } else {
            items.push(element);
          }
        }
      } else {
        this.common_alert("조회된 데이터가 없습니다", 'error');
      }
      this.treeItems = items;

    },
    selectTreeview(item){

      this.SELECTED_DIV_ID = item.id;
      this.SELECTED_NODE_LV = item.nodeLv;

      //분류트리 조회
      this.selectedTreeViewRoute(this.SELECTED_DIV_ID);

      //분류상세 조회
      this.selectedTreeviewDetail(this.SELECTED_DIV_ID);

    },
    async selectedTreeviewDetail(divId){
      let requestData = {
        headers: {},
        sendData: {},
      };

      //헤더 세팅
      requestData.headers["URL"] = "/api/km/category/manage/detail";
      requestData.headers["SERVICE"] = "km.category.manage.tr";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['DIV_ID'] = divId;                            //클릭 한 분류의 ID
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;    //고객사key

      let response = await this.common_postCall(requestData);
      this.selectedTreeviewDetailRes(response);
    },
    selectedTreeviewDetailRes(response){
      let data = response.DATA[0];

      this.REGR_USER_ATTR_NM = data.REGR_USER_ATTR_NM;
      this.REGR_NM = data.REGR_NM;
      this.REG_DTTM = data.REG_DTTM;
      this.AMDR_USER_ATTR_NM = data.AMDR_USER_ATTR_NM;
      this.AMDR_NM = data.AMDR_NM;
      this.UPD_DTTM = data.UPD_DTTM;
      this.PRE_NODE_KEY = data.SPST_DIV_ID;
      this.DIV_ID = data.DIV_ID;
      this.DIV_NM = data.DIV_NM;
      this.TMPLAT_TYPE_NM = data.TMPLAT_TYPE_NM;
      this.TMPLAT_TITL = data.TMPLAT_TITL;
      this.TMPLAT_ID = data.TMPLAT_ID;

      //화면표시안함 = N, 화면표시 = Y
      if(data.SCR_DISP_YN == 'N'){
        this.SCR_DISP_YN = true;
      } else{
        this.SCR_DISP_YN = false;
      }
    },
    async deleteTreeBtn(){
      let requestData = {
        headers: {},
        sendData: {},
      };

      //헤더 세팅
      requestData.headers["URL"] = "/api/km/category/manage/delete";
      requestData.headers["SERVICE"] = "km.category.manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['DIV_ID'] = this.SELECTED_DIV_ID;    //클릭 한 분류의 ID

      await this.common_postCall(requestData);

      //삭제후 재조회
      this.treeviewListBtn();

      //분류 상세 초기화
      this.resetDetail('', 'delete');
    },
    resetDetail(selectedId, type){
      //분류상세 초기화
      this.REGR_USER_ATTR_NM= '';
      this.REGR_NM= '';
      this.REG_DTTM= '';
      this.AMDR_USER_ATTR_NM= '';
      this.AMDR_NM= '';
      this.UPD_DTTM= '';
      this.PRE_NODE_KEY= selectedId;
      this.DIV_ID= '';
      this.DIV_NM= '';
      this.SCR_DISP_YN= false;
      this.TMPLAT_TYPE_NM= '';
      this.TMPLAT_TITL= '';

      if(type != 'insertTree'){
        //추가 시, 초기화 되지 않도록
        this.SELECTED_DIV_ID = '';
        this.SELECTED_NODE_LV = '';
      }
      if(type == 'delete' || type == 'select'){
        //삭제 후 초기화할 경우, 선택경로 초기화
        this.selectedTreeItems = [];
      }
    },
    newTreeView(){
      if(this.SELECTED_DIV_ID == '' || this.SELECTED_DIV_ID == null){
        this.common_alert("노드가 선택되지 않았습니다.", 'error');
        return;
      }

      //추가 버튼
      if(this.SELECTED_NODE_LV == '3'){
        this.common_alert('해당 레벨에는 추가할 수 없습니다.', 'error');
        return;
      }

      //분류상세 초기화
      this.resetDetail(this.SELECTED_DIV_ID, 'insertTree');
    },
    saveTreeView(){
      let dispYn = '';
      //화면 표시 안함 체크 여부 (체크 시, true)
      if(this.SCR_DISP_YN == true){
        dispYn = 'N';
      } else {
        dispYn = 'Y';
      }

      if(this.DIV_ID == '' || this.DIV_ID == null){
        //신규 저장
        let params = {
          SPST_DIV_ID: this.SELECTED_DIV_ID,
          SCR_DISP_YN: dispYn,
          DIV_NM: this.DIV_NM,
        }

        this.insertTreeView(params);
      }else{
        //수정 저장
        this.updateTreeView(dispYn);
      }
    },
    async updateTreeView(dispYn){
      //분류관리 수정
      let requestData = {
        headers: {},
        sendData: {},
      };
      //헤더 세팅
      requestData.headers["URL"] = "/api/km/category/manage/tr-clnm/modify";
      requestData.headers["SERVICE"] = "km.category.manage.tr-clnm";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['DIV_ID'] = this.SELECTED_DIV_ID;                                     //분류id
      requestData.sendData['DIV_NM'] = this.DIV_NM;                                              //분류명
      requestData.sendData['SPST_DIV_ID'] = this.PRE_NODE_KEY;                                   //상위분류ID
      requestData.sendData['TMPLAT_ID'] = this.TMPLAT_ID;                                        //템플릿ID
      requestData.sendData['SCR_DISP_YN'] = dispYn;                                              //화면표시여부
      requestData.sendData['AMDR_DEPT_CD'] = "x";                                                //수정자부서코드
      requestData.sendData['AMDR_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;    //수정자
      requestData.sendData['PROC_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;    //처리자

      await this.common_postCall(requestData);
      //저장후 재조회
      this.treeviewListBtn();

      //선택경로 재조회
      this.selectedTreeViewRoute(this.SELECTED_DIV_ID);

    },
    async insertTreeView(params){
      let requestData = {
        headers: {},
        sendData: {},
      };
      //1레벨 메뉴 추가 일 경우, 상위분류ID = 0 / 화면 표시여부 = Y
      let newDivId = await this.mixin_common_bizSeq_get("KMCC");

      //헤더 세팅
      requestData.headers["URL"] = "/api/km/category/manage/process";
      requestData.headers["SERVICE"] = "km.category.manage";
      requestData.headers["METHOD"] = "process";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;                          //고객사키
      requestData.sendData['DIV_ID'] = newDivId;                                               //분류ID
      requestData.sendData['DIV_NM'] = params.DIV_NM;                                          //분류명
      requestData.sendData['SPST_DIV_ID'] = params.SPST_DIV_ID;                                //상위분류ID (선택한 현재 경로가 상위id가 됨)
      requestData.sendData['TMPLAT_ID'] = this.TMPLAT_ID;                                      //템플릿ID
      requestData.sendData['SCR_DISP_YN'] = params.SCR_DISP_YN;                                //화면표시여부
      requestData.sendData['USE_YN'] = 'Y';                                                    //사용여부 (default Y)
      requestData.sendData['REGR_DEPT_CD'] = 'x';                                              //등록자부서코드
      requestData.sendData['REGR_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;  //등록자ID
      requestData.sendData['PROC_ID'] = this.$store.getters['userStore/GE_USER_ROLE'].userId;  //처리자

      await this.common_postCall(requestData);

      //저장후 재조회
      this.treeviewListBtn();
      this.selectedTreeviewDetail(newDivId);

      //선택경로 재조회
      this.selectedTreeViewRoute(newDivId);
    },
    selectedTmplat(params){
      this.TMPLAT_ID = params.TMPLAT_ID ;
      this.TMPLAT_TITL = params.TMPLAT_TITL;
    },
    resetTmplat(){
      this.TMPLAT_ID = '';
      this.TMPLAT_TITL = '';
    },
    mainPreview(){
      //지식컨텐츠 분류상세 > 템플릿
      if( this.TMPLAT_ID == '' ||  this.TMPLAT_ID == null){
        this.common_alert("등록된 템플릿이 없습니다", 'error');
      }
      let previewParams = {
        TMPLAT_ID: this.TMPLAT_ID
      }
      this.previewTmplat(previewParams);

    },
    async previewTmplat(previewParams){

      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/km/template/detail/inqire";
      requestData.headers["SERVICE"] = 'km.template.detail';
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["TMPLAT_ID"] = previewParams.TMPLAT_ID;       //템플릿ID
      requestData.sendData["ASP_NEWCUST_KEY"] =this.ASP_NEWCUST_KEY;      //회사구분


      let response = await this.common_postCall(requestData);
      this.previewTmplatRes(response);

    },
    previewTmplatRes(response){
      let data = response.DATA;

      this.previewParams = {
        id : data[0].TMPLAT_ID,
        contn : this.restoreXSS_CKeditor(data[0].CONTN),
      }

      //미리보기 모달로
      this.showDialog('M510101P03');
    },
    async selectedTreeViewRoute(id){
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/km/category/manage/tr/detail";
      requestData.headers["SERVICE"] = 'km.category.manage.tr';
      requestData.headers["METHOD"] = "detail";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["DIV_ID"] = id;    //분류ID

      let response = await this.common_postCall(requestData);
      this.selectedTreeViewRouteRes(response);

    },
    selectedTreeViewRouteRes(response){
      let data = response.DATA;
      let items = [];

      if(data.length !=0 ){
        for(var i=0; i<data.length; i++){
          let element = {
            cNode: data[i].C_NODE_NO,       //분류ID
            pNode: data[i].P_NODE_NO,       //상위분류ID
            id: data[i].NODE_ID,            //item-key
            name: data[i].NODE_TITLE,       //item-text
            nodeLv: data[i].NODE_LVL ,      //분류 레벨 (start 0)
            nodeSort: data[i].NODE_SORT,    //정렬순서
            nodeType: data[i].NODE_TYPE,    //default 'D'
            scrDispYn: data[i].SCR_DISP_YN, //화면표시
          }

          element.children = [];
          if(data[i].SCR_DISP_YN == "N"){
            element["locked"] = true;
          }

          if(element.nodeLv == 2){
            for(var j=0; j<items.length; j++){
              if(element.pNode == items[j].cNode) {
                items[j].children.push(element);
              }
            }
          } else if (element.nodeLv == 3){
            //마지막 lv
            for(var k=0; k<items.length; k++){
              for(var l=0; l<items[k].children.length; l++){
                if(element.pNode == items[k].children[l].cNode){
                  items[k].children[l].children.push(element);
                }
              }
            }
          } else {
            items.push(element);
          }
        }
      } else {
        this.common_alert("조회된 데이터가 없습니다", 'error');
      }
      this.selectedTreeItems = items;
    }
  },
  computed: {},

  mounted(){
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
};
</script>

<style></style>
